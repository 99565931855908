import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { Row, Col, Button } from 'react-bootstrap';
import SearchBox from 'react-search-box'; 
import { formatCurrency } from '../util';
const urlconfig = require('../config/urlconfig');

const ApprovalDashboard = () => {
  const { account, username } = useAuth();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [originalItem, setOriginalItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]); 
  const [searchQuery, setSearchQuery] = useState(''); 
  const [remarksModal, setRemarkModal] = useState(false);
  const [remarkText, setRemarkText] = useState('')

  const fetchData = async () => {
    try {
      const response = await fetch(`${urlconfig.frontend_server}/api/items`);
      if (!response.ok) {
        throw new Error('Failed to fetch items from the server');
      }
      const data = await response.json();
      const sortedItems = data.items ? data.items : data;
      sortedItems.sort((a, b) => new Date(b.CreatedAT) - new Date(a.CreatedAT));
      setItems(data);
      setFilteredItems(data); 
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch items from the server');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (item) => {
    setSelectedItem({ ...item });
    setOriginalItem({ ...item });
    setShowModal(true);
    
  };

  const sendCostSheetEmail = async (data) => {
    // console.log('Sending email to:', selectedItem.userEmail);
  //   console.log('selectedItem:', selectedItem);
  // console.log('Sending email to:', selectedItem?.userEmail);
  //   if (!selectedItem.userEmail) {
  //     console.error('No email address found');
  //     return;
  //   }
    const response = await fetch(`${urlconfig.backend_api}/api/v2/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(data),
      body: JSON.stringify({subject:`A cost sheet approval status has been updated by ${username}.` , 
         salesOrderNo: selectedItem.salesOrderNo, 
         createdBy:selectedItem.createdBy,
         email: selectedItem.userEmail 
        }),
    });
  
    if (response.ok) {
      console.log('Cost sheet sent successfully');
    } else {
      console.error('Failed to send cost sheet');
    }
  };

  

  const handleApproval = async (status) => {
    setSelectedItem({
      ...selectedItem,
      pending: status,
      remark: ''
    });
    await sendCostSheetEmail();
    setShowModal(false); 

    try {

      const updatedApprovedBy = status === false
      ? [username] 
      : Array.isArray(selectedItem.ApprovedBy)
        ? selectedItem.ApprovedBy.includes(username)
          ? selectedItem.ApprovedBy
          : [...selectedItem.ApprovedBy, username]
        : [username];

      const updatedData = {
        ...selectedItem,
        pending: status,
        ApprovedBy: updatedApprovedBy,
        remarks: remarkText,
      };

      // const response = await fetch(`${urlconfig.frontend_server}/api/frontend-edit-costsheet/${selectedItem._id}`, {
        const response = await fetch(`${urlconfig.backend_api}/api/v2/edit-costsheet/${selectedItem._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Updated Item:', data);
        fetchData(); 
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.error);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Filter items based on search query
  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredItems(items); 
    } else {
      const filtered = items.filter((item) =>
        (item.salesOrderNo && item.salesOrderNo.toLowerCase().includes(query.toLowerCase())) ||
        (item.clientName && item.clientName.toLowerCase().includes(query.toLowerCase())) ||
        (item.salesPOC && item.salesPOC.toLowerCase().includes(query.toLowerCase())) ||
        (item.scmspoc && item.scmspoc.toLowerCase().includes(query.toLowerCase())) ||
        (item.costsheetfor && item.costsheetfor.toLowerCase().includes(query.toLowerCase())) ||
        (item.costsheetfor && item.costsheetfor.toLowerCase().includes(query.toLowerCase()))
        // (item.pending && item.pending.toLowerCase().includes(query.toLowerCase()))
      );
      setFilteredItems(filtered); 
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handlerejectRemarks =()=>{
    setRemarkModal(true);
  }

  const approvers = ['Rahul Rasa', 'Vamsi Perugu', 'Sanjeev']

  const groupItemsBySalesOrderNo = (items) => {
    return items.reduce((groups, item) => {
      if (!groups[item.salesOrderNo]) {
        groups[item.salesOrderNo] = [];
      }
      groups[item.salesOrderNo].push(item);
      return groups;
    }, {});
  };
  const groupedItems = groupItemsBySalesOrderNo(filteredItems);


  return (
    <div>
      <h1>Approval Dashboard</h1>
      <h3>Username: {username}</h3>
      {/* Search Bar using package react-search-box*/}
      <SearchBox
        value={searchQuery}
        onChange={(query) => handleSearch(query)} // Call the handleSearch function on search query change
        placeholder="Search by Sales Order No, Client Name, Created By or Sales SPOC "
      />

      <table>
        <thead>
          <tr>
            <th>Costsheet For</th>
            <th>Client Name</th>
            <th>Sales Order No</th>
            <th>Sales SPOC</th>
            <th>SCM SPOC</th>
            <th>Details</th>
            <th>Created By</th>
            <th>Approval Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* {filteredItems.length > 0 ? (
            filteredItems.map((item) => ( */}
            {Object.keys(groupedItems).map((salesOrderNo) => {
              const itemsInOrder = groupedItems[salesOrderNo];
              const firstItem = itemsInOrder[0];
              return(
              <tr key={salesOrderNo} onClick={() => handleRowClick(firstItem)} style={{ cursor: 'pointer' }}>
                <td>{firstItem.costsheetfor}</td>
                <td>{firstItem.clientName}</td>
                <td>{firstItem.salesOrderNo}</td>
                <td>{firstItem.salesPOC}</td>
                <td>{firstItem.scmspoc}</td>
                {/* <td>{item.details}</td> */}
                
                {/* <td>{itemsInOrder.map((item)=>item.details).join(", ")}</td> */}
                <td>{firstItem.items && firstItem.items.map((item)=>item.details).join(", ")}</td>

                <td>{firstItem.createdBy}</td>
                {/* <td>
                  {item.pending === "undefined" || item.pending === undefined || item.pending === null || item.pending === '' 
                    ? 'Pending ' 
                    : item.pending === "true" || item.pending === true
                    ? 'Approved By ' 
                    : item.pending === "false" || item.pending === false
                    ? 'Rejected By ' 
                    : 'Unknown'}  
                     {item.ApprovedBy}
                </td> */}

                  <td>
                  {firstItem.pending === undefined || firstItem.pending === null || firstItem.pending === ''
            ? 'Pending'
            : firstItem.pending === 'true' || firstItem.pending === true
            ? `Approved By ${firstItem.ApprovedBy} `
            : firstItem.pending === 'false' || firstItem.pending === false
            ? 
            (
              <span>
                Rejected By {firstItem.ApprovedBy} <br></br>
                
                <span 
                  data-toggle="tooltip"
                  data-placement="top"
                  title={firstItem.remarks}
                  style={{  color: 'red', fontSize:"12px", fontWeight:'bold' }}
                >
                  Reject Note: {firstItem.remarks}
                </span>
              </span>
            )
            : 'Pending'}
                  </td>
                <td>
                  <Button onClick={() => handleRowClick(firstItem)} style={{ cursor: 'pointer' }}>View </Button>
                </td>

              </tr>
            );
          })}
          
        </tbody>
      </table>

      {/* Edit Modal */}
      {showModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog w-50">
            <div className="modal-content" style={{ transform: 'translate(-50%, -0%)', width:"1100px" }}>
              <div className="modal-header">
                <Row className='w-100 d-flex justify-content-between align-items-center'>
                  <Col md={9}>
                <h5 className="modal-title p-0">OrderNO:- {selectedItem.salesOrderNo}</h5>
                </Col>
                <Col md={2}>
                 <span className='float-end' onClick={()=>(setShowModal(false))}>
                    <Button >X</Button>
                    </span>
                    </Col>
                  </Row>
              </div>
              <div className='modal-body w-100'>
                <p><b>Cost Sheet for:-</b> {selectedItem.costsheetfor}</p>
                <table className="table table-bordered">
  <thead>
    {/* <tr>
      <th colSpan="15" className="text-center">Client Name: {selectedItem.clientName}</th>
    </tr> */}
    <tr>
      <th colSpan="15" className="text-center">COST SHEET</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td colSpan="5"><b>Date:</b> {new Date(selectedItem.date).toLocaleDateString('en-GB')}</td>
      
      <td colSpan="4"><b>Created By:</b> {selectedItem.createdBy}</td>
      <td colSpan="6">Email: {selectedItem.userEmail}</td>
    </tr>
    <tr>
      <td colSpan="8"><b>Name Of the Client:</b> {selectedItem.clientName}</td>
      
      <td colSpan="7"><b>Sales Order No:</b> {selectedItem.salesOrderNo}</td>
    </tr>
    <tr>
      <td colSpan={3}><b>Sales SPOC:</b> {selectedItem.salesPOC}</td>
      <td colSpan={3}><b>SCM SPOC:</b> {selectedItem.scmspoc}</td>
      <td colSpan={4}><b>Client Payment terms:</b> {selectedItem.clientPaymentTerms}</td>
      <td colSpan={3}><b>Location</b> {selectedItem.location}</td>
      
     
    </tr>
    <tr>
      <th>Details</th>
      <th>Qty</th>
      <th>GST</th>
      <th>PP (excl GST)</th>
      <th>PP (incl GST)</th>
      <th>SP (excl GST)</th>
      <th>SP (incl GST)</th>
      <th>Total PP (incl GST)</th>
      <th>Total SP (incl GST)</th>
      <th>Margin</th>
      <th>Margin %</th>
      <th>Vendor</th>
      <th>Payment terms</th>
    </tr>

    {/* Loop through each item in selectedSalesOrder */}
    {/* {groupedItems[selectedItem.salesOrderNo] && groupedItems[selectedItem.salesOrderNo].map((item, index) => ( */}
    {selectedItem?.items && selectedItem.items.length > 0 ? (
      selectedItem.items.map((item, index) => (
    <tr key={index}>
      <td>{groupedItems && item.details}</td>
      <td>{item.qty}</td>
      <td>{item.gst}%</td>
      <td>{formatCurrency(item.pp)}</td>
      <td>{formatCurrency(item.pp + (item.pp * item.gst)/100)}</td>
      <td>{formatCurrency(item.sp)}</td>
      <td>{formatCurrency(item.sp + (item.sp * item.gst)/100)}</td>
      <td>{formatCurrency((item.qty) * (item.pp + (item.pp * item.gst)/100))}</td>
      <td>{formatCurrency((item.qty) * (item.sp + (item.sp * item.gst)/100))}</td>
      <td>{formatCurrency( item.qty * (item.sp + (item.sp * item.gst) / 100) - item.qty * (item.pp + (item.pp * item.gst) / 100))}  </td>
      <td>{(((item.qty * (item.sp + (item.sp * item.gst) / 100) - item.qty * (item.pp + (item.pp * item.gst) / 100)) / (item.qty * (item.pp + (item.pp * item.gst) / 100))) *100).toFixed(1)} %</td>
      <td>{item.vendorName}</td>
      <td>{item.vendorPaymentTerms}</td>
    </tr>
    ))):<>No data available</>}
    

    {/* Additional rows for sub-total, transport, customization, etc. */}

    <tr>
      <td>Transportation</td>
      <td colSpan="6"></td>
      <td>{formatCurrency(selectedItem?.items && selectedItem.items[0]?.transportationTotalPp)}</td>
      <td>{formatCurrency(selectedItem?.items && selectedItem.items[0]?.transportationTotalSp)}</td>
      <td>{formatCurrency((selectedItem?.items && selectedItem.items[0]?.transportationTotalSp)-(selectedItem?.items && selectedItem.items[0]?.transportationTotalPp))}</td>
      <td>{(((selectedItem?.items && selectedItem.items[0]?.transportationTotalSp)-(selectedItem?.items[0]?.transportationTotalPp))/(selectedItem?.items[0]?.transportationTotalPp)*100).toFixed(1)}%</td>
      <td colSpan="2"></td>
    </tr>
    <tr>
      <td>Customization</td>
      <td colSpan="6"></td>
      <td>{formatCurrency(selectedItem?.items && selectedItem.items[0]?.customizationTotalPp)}</td>
      <td>{formatCurrency(selectedItem?.items && selectedItem.items[0]?.customizationTotalSp)}</td>
      <td>{formatCurrency((selectedItem?.items && selectedItem.items[0]?.customizationTotalSp)-(selectedItem?.items && selectedItem.items[0]?.customizationTotalPp))}</td>
      <td>{(((selectedItem?.items && selectedItem.items[0]?.customizationTotalSp)-(selectedItem?.items[0]?.customizationTotalPp))/(selectedItem?.items[0]?.customizationTotalPp)*100).toFixed(1)}%</td>
      <td colSpan="2"></td>
    </tr>
    <tr>
      <td>Marketing</td>
      <td colSpan="6"></td>
      <td>{formatCurrency(selectedItem?.items && selectedItem.items[0]?.marketingTotalPp)}</td>
      <td>{formatCurrency(selectedItem?.items && selectedItem.items[0]?.marketingTotalSp)}</td>
      <td>{formatCurrency((selectedItem?.items && selectedItem.items[0]?.marketingTotalSp)-(selectedItem?.items && selectedItem.items[0]?.marketingTotalPp))}</td>
      <td>{(((selectedItem?.items &&  selectedItem.items[0]?.marketingTotalSp)-(selectedItem?.items[0]?.marketingTotalPp))/(selectedItem?.items[0]?.marketingTotalPp)*100).toFixed(1) }%</td>
      <td colSpan="2"></td>
    </tr>
    <tr>
      <td>Grand-Total</td>
      <td colSpan="6"></td>
      {/* <td>{((selectedItem.qty) * (selectedItem.nlc)+(selectedItem.transportationTotalPp)+(selectedItem.marketingTotalPp)+(selectedItem.customizationTotalPp)).toFixed(1)}</td> */}
      {/* {(
  groupedItems[selectedItem.salesOrderNo].reduce((total, item) => total + (item.qty * item.nlc), 0) + 
  selectedItem.transportationTotalPp + 
  selectedItem.customizationTotalPp + 
  selectedItem.marketingTotalPp
).toFixed(1)} */}
<td>
  {/**totalpp */}
  {formatCurrency(parseFloat(selectedItem?.items?.reduce((acc, item) => acc + (item.qty * (item.pp + (item.pp * item.gst)/100)), 0) +
    (selectedItem?.items[0]?.transportationTotalPp || 0) +
    (selectedItem?.items[0]?.marketingTotalPp || 0) +
    (selectedItem?.items[0]?.customizationTotalPp || 0)))}
</td>

      {/* <td>{((selectedItem.qty)*(selectedItem.nsp)+(selectedItem.transportationTotalSp)+(selectedItem.customizationTotalSp)+(selectedItem.marketingTotalSp)).toFixed(1)}</td> */}
      <td>  
        {/* {(
          groupedItems[selectedItem.salesOrderNo].reduce((total, item) => total + (item.qty * item.nsp), 0) + 
          selectedItem.transportationTotalSp + 
          selectedItem.customizationTotalSp + 
          selectedItem.marketingTotalSp
        ).toFixed(1)} */}
        {/**totalSP */}
         {formatCurrency(parseFloat(selectedItem?.items?.reduce((acc, item) => acc + (item.qty * (item.sp + (item.sp * item.gst)/100)), 0) +
            (selectedItem?.items[0]?.transportationTotalSp || 0) +
            (selectedItem?.items[0]?.marketingTotalSp || 0) +
            (selectedItem?.items[0]?.customizationTotalSp || 0)).toFixed(1))}

   
      </td>
      {/* <td>{((selectedItem.margin)+((selectedItem.transportationTotalSp)-(selectedItem.transportationTotalPp))+((selectedItem.customizationTotalSp)-(selectedItem.customizationTotalPp))+(selectedItem.marketingTotalSp)-(selectedItem.marketingTotalPp)).toFixed(1)} </td> */}
      <td>
          {/* {(
            selectedItem.items && groupedItems[selectedItem[0]?.salesOrderNo].reduce((total, item) => total + item.margin, 0) + 
            (selectedItem?[0].items.transportationTotalSp - selectedItem?[0].items[0].transportationTotalPp) + 
            (selectedItem.customizationTotalSp - selectedItem.customizationTotalPp) + 
            (selectedItem.marketingTotalSp - selectedItem.marketingTotalPp)
          ).toFixed(1)} */}

         {formatCurrency(
              parseFloat(
                selectedItem?.items?.reduce((acc, item) => acc + (item.qty * (item.sp + (item.sp * item.gst)/100)), 0) -
                selectedItem?.items?.reduce((acc, item) => acc + (item.qty * (item.pp + (item.pp * item.gst)/100)), 0) +
                (selectedItem?.items[0]?.transportationTotalSp - selectedItem?.items[0]?.transportationTotalPp || 0) +
                (selectedItem?.items[0]?.customizationTotalSp - selectedItem?.items[0]?.customizationTotalPp || 0) +
                (selectedItem?.items[0]?.marketingTotalSp - selectedItem?.items[0]?.marketingTotalPp || 0)
              )
            )
          }

      </td>
      {/* <td>{(((selectedItem.margin)+((selectedItem.transportationTotalSp)-(selectedItem.transportationTotalPp))+((selectedItem.customizationTotalSp)-(selectedItem.customizationTotalPp))+(selectedItem.marketingTotalSp)-(selectedItem.marketingTotalPp))/((selectedItem.qty) * (selectedItem.nlc)+(selectedItem.transportationTotalPp)+(selectedItem.marketingTotalPp)+(selectedItem.customizationTotalPp))*100).toFixed(1)}%</td> */}
      <td>
          {/* {(
            (
              groupedItems[selectedItem.salesOrderNo].reduce((total, item) => total + item.margin, 0) + 
              (selectedItem.transportationTotalSp - selectedItem.transportationTotalPp) + 
              (selectedItem.customizationTotalSp - selectedItem.customizationTotalPp) + 
              (selectedItem.marketingTotalSp - selectedItem.marketingTotalPp)
            ) / 
            (
              groupedItems[selectedItem.salesOrderNo].reduce((total, item) => total + (item.qty * item.nlc), 0) + 
              selectedItem.transportationTotalPp + 
              selectedItem.marketingTotalPp + 
              selectedItem.customizationTotalPp
            ) * 100
          ).toFixed(1)} */}

            {parseFloat(
                  (selectedItem?.items?.reduce((acc, item) => acc + (item.qty * ((item.sp + (item.sp * item.gst)/100))), 0) -
                  selectedItem?.items?.reduce((acc, item) => acc + (item.qty * ((item.pp + (item.pp * item.gst)/100))), 0) +
                  (selectedItem?.items[0]?.transportationTotalSp - selectedItem?.items[0]?.transportationTotalPp || 0) +
                  (selectedItem?.items[0]?.customizationTotalSp - selectedItem?.items[0]?.customizationTotalPp || 0) +
                  (selectedItem?.items[0]?.marketingTotalSp - selectedItem?.items[0]?.marketingTotalPp || 0)) /
                  (selectedItem?.items?.reduce((acc, item) => acc + (item.qty * ((item.pp + (item.pp * item.gst)/100))), 0) +
                  (selectedItem?.items[0]?.transportationTotalPp || 0) +
                  (selectedItem?.items[0]?.marketingTotalPp || 0) +
                  (selectedItem?.items[0]?.customizationTotalPp || 0)) * 100
                ).toFixed(1)}%

      </td>

      <td colSpan="2"></td>
    </tr>
  </tbody>
</table>
        
              </div>
              
              <div className='d-flex justify-content-between'>
              <Button onClick={handlerejectRemarks} className='btn btn-danger' style={{backgroundColor: "#bb2124"}}>Reject</Button>
              <Button onClick={() => handleApproval(true)} className='btn btn-success'>Approve</Button>
              {/* <Button onClick={() => handleApproval(false)} className='btn btn-danger'>Reject</Button> */}
              {/* <Button onClick={handlerejectRemarks} className='btn btn-danger'>Reject</Button> */}
              </div>
            </div>
          </div>
        </div>
      )}

{remarksModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog w-50">
            <div className="modal-content" style={{ width: "500px", transform: 'translate(-50%, -0%)' }}>
              <div className="modal-header">
                <Row className='d-flex w-100'>
                  <Col md={9}>
                <h5 className="modal-title">Add Remark For Rejection</h5>
                </Col>
                <Col md={3}>
                <Button onClick={() => setRemarkModal(false)} className="close">X</Button>
                </Col>
                </Row>
              </div>
              <div className="modal-body">
                <input
                  // value={remarkText}
                  // onChange={(e) => setRemarkText(e.target.value)}
                  rows="4"
                  value={remarkText}
                  onChange={(e) => setRemarkText(e.target.value)}
                  className="form-control"
                  placeholder="Enter your remark for rejection"
                  name='remarks'
                  required
                ></input>
              </div>
              <div className="modal-footer">
                {/* <Button onClick={handleSubmitRemark} className="btn btn-primary">Submit Remark</Button> */}
                <Button onClick={() => setRemarkModal(false)} className="btn btn-secondary">Close</Button>
                {/* <Button onClick={() => {handleApproval(false); setRemarkModal(false)} setRemarkText('')} className='btn btn-danger'>Reject</Button> */}
                {/* <Button onClick={() => { handleApproval(false); setRemarkModal(false); setRemarkText(''); }} className='btn btn-danger'>Reject</Button> */}
                <Button 
            onClick={() => { handleApproval(false); setRemarkModal(false); setRemarkText(''); }} 
            className='btn btn-danger'
            disabled={remarkText.trim() === ''} // Disable if remarkText is empty
          >
            Reject
          </Button>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovalDashboard;
