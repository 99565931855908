export const formatCurrency = (amount) => {
  if (amount === null || amount === undefined || isNaN(amount)) {
    return `0.0`; 
  }
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'decimal',  
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(amount);

  return formattedAmount; 
};
