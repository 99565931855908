// import React, { createContext, useContext, useEffect, useState } from "react";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { redirect } from "react-router-dom";



import React, { createContext, useContext, useEffect, useState } from "react";     //NEW FOR SESSION AND TOKEN GENERATION
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const urlconfig = require('./config/urlconfig');

const msalConfig = {
  auth: {
    clientId: "6a26564b-a7a3-467e-b884-d3e4443f9ca9",
    authority: "https://login.microsoftonline.com/da5284b0-ca6e-4343-ba1a-24725c532d09",
    redirectUri: `${urlconfig.frontend}/login`,
  },
  cache: {
     cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
   scopes: ["User.Read"],
  
};

const msalInstance = new PublicClientApplication(msalConfig);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [email, setEmail] = useState(sessionStorage.getItem("email") || null);
  const [username, setUsername] = useState(sessionStorage.getItem("username") || null);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        console.log("Initializing MSAL...");
        await msalInstance.initialize();
        console.log("Initialized MSAL...");
        const response = await msalInstance.handleRedirectPromise();

        if (response) {
          const { account } = response;
          setAccount(account);
          setEmail(account.username);
          setUsername(account.name);

          // Store session details in sessionStorage
          sessionStorage.setItem("email", account.username);
          sessionStorage.setItem("username", account.name);
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts.length === 1) {
            const account = currentAccounts[0];
            setAccount(account);
            setEmail(account.username);
            setUsername(account.name);

            // Store session details in sessionStorage
            sessionStorage.setItem("email", account.username);
            sessionStorage.setItem("username", account.name);
          }
        }
      } catch (error) {
        console.error("MSAL initialization or redirect error:", error);
      }
    };

    handleRedirect();
  }, []);

  const signIn = () => {
    msalInstance.loginRedirect(loginRequest);
  };

  const signOut = () => {
    msalInstance.logoutRedirect(); // Redirect to logout
    setEmail(null);
    setUsername(null);
    setAccount(null);

    // Clear session details from sessionStorage
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("username");
  };

  return (
    <AuthContext.Provider value={{ account, signIn, signOut, email, username }}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
