import React, { useState, useRef, useEffect } from 'react';
import './userinput.css';
import {Modal, Button, Row, FormSelect, Col} from 'react-bootstrap';
import { useAuth } from './AuthProvider';
const urlconfig = require('./config/urlconfig');

const UserInput = () => {
  const {username, email } = useAuth();
  const initialItemState = {
    details: '',
    qty: '',
    gst: '',
    pp: '',
    nlc: '',
    sp: '',
    nsp: '',
    totalPp: '',
    totalSp: '',
    transportationTotalSp: '',
    customizationTotalSp: '',
    marketingTotalSp: '',
    margin: '',
    marginPercent: '',
    vendorName: '',
    vendorPaymentTerms: '',
    location:'',
  };

  const [formData, setFormData] = useState({
    date: '',
    createdBy:'',
    userEmail:'',
    clientName: '',
    clientPaymentTerms: '',
    costsheetfor:'',
    salesOrderNo: '',
    salesPOC: '',
    scmspoc: '',
    items: [initialItemState],
    transportationTotalPp: '',
    customizationTotalPp: '',
    marketingTotalPp: '',
    transportationTotalSp: '',
    customizationTotalSp: '',
    marketingTotalSp: '',
    totalPP: '',
    totalSP: '',
    totalMargin: '',
    totalMarginPercent: '',
    pending: ''
  });

  const [collapsedItems, setCollapsedItems] = useState([]);
  const downloadLinkRef = useRef(null);

  const [showCustomClientPaymentTerm, setShowCustomClientPaymentTerm] = useState(false);
  const [showCustomVendorPaymentTerm, setShowCustomVendorPaymentTerm] = useState(false);

  const [customClientPaymentTerm, setCustomClientPaymentTerm] = useState('');
  const [customVendorPaymentTerm, setCustomVendorPaymentTerm] = useState('');
  const [salesOrderExists, setSalesOrderExists] = useState(false);
  const [salesOrderErrorMessage, setSalesOrderErrorMessage] = useState('');

useEffect(() => {
    const transportationTotalPp = parseFloat(formData.transportationTotalPp) || 0;
    const transportationTotalSp = parseFloat(formData.transportationTotalSp) || 0;
    const customizationTotalPp = parseFloat(formData.customizationTotalPp) || 0;
    const customizationTotalSp = parseFloat(formData.customizationTotalSp) || 0;
    const marketingTotalPp = parseFloat(formData.marketingTotalPp) || 0;
    const marketingTotalSp = parseFloat(formData.marketingTotalSp) || 0;

    const totalPP = formData.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
    // const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
    // const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
    const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0) + transportationTotalSp + customizationTotalSp + marketingTotalSp;
    const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - transportationTotalSp - customizationTotalPp - customizationTotalSp - marketingTotalPp -marketingTotalSp;
    const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;

    setFormData(prevState => ({
      ...prevState,
      createdBy:username,
      userEmail:email,
      totalPP: totalPP.toFixed(2),
      totalSP: totalSP.toFixed(2),
      totalMargin: totalMargin.toFixed(2),
      totalMarginPercent: totalMarginPercent.toFixed(2),
    }));
  }, [formData.items, formData.transportationTotalPp, formData.transportationTotalSp, formData.customizationTotalPp, formData.customizationTotalSp, formData.marketingTotalPp, formData.marketingTotalSp]);
  // [formData.items, formData.transportationTotalPp, formData.customizationTotalPp, formData.marketingTotalPp]);

const handleFormDataChange = (field, value) => {
  setFormData(prevState => ({
    ...prevState,
    [field]: value
  }));

  if (field === 'clientPaymentTerms' && value==='custom') {
    setShowCustomClientPaymentTerm(true);
  }
  // else{
  //   setShowCustomClientPaymentTerm(false)
  // }

  if(field==='vendorPaymentTerms'&& value === 'custom'){
    setCustomVendorPaymentTerm(true);
  }
  // else{
  //   setCustomVendorPaymentTerm(false);
  //   setCustomVendorPaymentTerm('');
  // }

  if (field === 'salesOrderNo') {
    checkSalesOrderExists(value);
  }
};

const checkSalesOrderExists = async (salesOrderNo) => {
  try {
    const encodedSalesOrderNo = encodeURIComponent(salesOrderNo);
    const response = await fetch(`${urlconfig.backend_api}/api/v2/check-sales-order/${encodedSalesOrderNo}`);
    const data = await response.json();
    if (data.exists) {
      setSalesOrderExists(true);
      setSalesOrderErrorMessage(data.message);  
    } else {
      setSalesOrderExists(false);
      setSalesOrderErrorMessage(''); 
    }
  } catch (error) {
    console.error('Error checking sales order number:', error);
    setSalesOrderExists(false);
    setSalesOrderErrorMessage('');
  }
};

// const handleBlur = async (field) => {
//   if (field === 'salesOrderNo') {
//       const salesOrderNo = formData.salesOrderNo;
//       console.log('Sales Order No on Blur:', salesOrderNo); // Debugging line

//       // Check if salesOrderNo is valid (non-empty)
//       if (salesOrderNo && salesOrderNo.trim() !== '') {
//           await checkSalesOrderExists(salesOrderNo);  // Call API to check existence
//       } else {
//           setSalesOrderExists(false);  // Reset the state if the number is invalid
//           setSalesOrderErrorMessage('Please enter a valid sales order number');
//       }
//   }
// };
const handleBlur = async (field) => {
  if (field === 'salesOrderNo') {
    const salesOrderNo = formData.salesOrderNo.trim();
    console.log('salesorderNO', salesOrderNo);
    if (salesOrderNo) {
      await checkSalesOrderExists(salesOrderNo);
    }
  }
};


  const handleItemChange = (index, field, value) => {
    const updatedItems = formData.items.map((item, idx) => {
      if (idx === index) {
        const updatedItem = { ...item, [field]: value };

        if (field === 'vendorPaymentTerms') {
          setShowCustomVendorPaymentTerm(value === 'custom');
        }

        // Perform calculations
        const pp = parseFloat(updatedItem.pp) || 0;
        const sp = parseFloat(updatedItem.sp) || 0;
        const gst = parseFloat(updatedItem.gst) || 0;
        const qty = parseInt(updatedItem.qty) || 0;

        const nlc = pp + (pp * gst / 100);
        const nsp = sp + (sp * gst / 100);
        const totalPp = nlc * qty;
        const totalSp = nsp * qty;
        const margin = totalSp - totalPp;
        const marginPercent = totalPp ? (margin / totalPp) * 100 : 0;

        updatedItem.nlc = nlc.toFixed(2);
        updatedItem.nsp = nsp.toFixed(2);
        updatedItem.totalPp = totalPp.toFixed(2);
        updatedItem.totalSp = totalSp.toFixed(2);
        updatedItem.margin = margin.toFixed(2);
        updatedItem.marginPercent = marginPercent.toFixed(2);

        return updatedItem;
      }
      return item;
    });

    setFormData(prevState => ({
      ...prevState,
      items: updatedItems,
    }));

    calculateTotals();
  };

  const calculateTotals = () => {
      const transportationTotalPp = parseFloat(formData.transportationTotalPp) || 0;
      const transportationTotalSp = parseFloat(formData.transportationTotalSp) || 0;
      const customizationTotalPp = parseFloat(formData.customizationTotalPp) || 0;
      const customizationTotalSp = parseFloat(formData.customizationTotalSp) || 0;
      const marketingTotalPp = parseFloat(formData.marketingTotalPp) || 0;
      const marketingTotalSp = parseFloat(formData.marketingTotalSp) || 0;

      const totalPP = formData.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
      // const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
      // const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
      const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0) + transportationTotalSp + customizationTotalSp + marketingTotalSp ;
      const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp - transportationTotalSp - customizationTotalSp - marketingTotalSp;
      const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;

      setFormData(prevState => ({
        ...prevState,
        totalPP: totalPP.toFixed(2),
        totalSP: totalSP.toFixed(2),
        totalMargin: totalMargin.toFixed(2),
        totalMarginPercent: totalMarginPercent.toFixed(2),
      }));
    };

  const addItem = () => {
    setFormData(prevState => ({
      ...prevState,
      items: [...prevState.items, initialItemState],
    }));
    setCollapsedItems(prevState => [...prevState, false]);
  };

  const removeItem = (index) => {
    setFormData(prevState => ({
      ...prevState,
      items: prevState.items.filter((_, idx) => idx !== index),
    }));
    setCollapsedItems(prevState => prevState.filter((_, idx) => idx !== index));
     calculateTotals();
  };

  const toggleCollapse = (index) => {
    setCollapsedItems(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  const sendCostSheetEmail = async (data) => {
    const response = await fetch(`${urlconfig.backend_api}/api/v2/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(data),
      body: JSON.stringify({ salesOrderNo: formData.salesOrderNo, createdBy: formData.createdBy, userEmail:formData.userEmail }),
    });
  
    if (response.ok) {
      console.log('Cost sheet sent successfully');
    } else {
      console.error('Failed to send cost sheet');
    }
  };
  
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async(e)=>{
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    handleShow()
  }

  // old handleConfirmSubmit
  // const handleConfirmCostSheet = async(e)=>{
  //   e.preventDefault();
  //   setLoading(true);
  //     await updateMasterCSV(formData);
  //     await sendCostSheetEmail();
  //     clearForm();
  //     handleClose();
  //     generateCSV(formData);
  //     setLoading(false);
  //   }
  const handleConfirmCostSheet = async () => {
    setLoading(true); 
    try {
      const paymentTerm = formData.clientPaymentTerms === 'custom' 
        ? customClientPaymentTerm 
        : formData.clientPaymentTerms;
      // console.log(paymentTerm);

      const updatedItems = formData.items.map(item => {
        const vendorPaymentTerm = item.vendorPaymentTerms === 'custom'
          ? customVendorPaymentTerm
          : item.vendorPaymentTerms;
        // console.log(vendorPaymentTerm);
        
        return {
          ...item,
          vendorPaymentTerms: vendorPaymentTerm
        };
      });

      await updateMasterCSV({ ...formData, clientPaymentTerms: paymentTerm, 
        items: updatedItems
       });
      await sendCostSheetEmail();
      clearForm();
      handleClose(); 
      generateCSV(formData);
    } catch (error) {
      console.error('Error in handleConfirmCostSheet:', error);
    } finally {
      setLoading(false);
    }
  };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
    
  //   handleShow(); 
  //   generateCSV(formData);
  //   await updateMasterCSV(formData);
  //   await sendCostSheetEmail();
    
  //   clearForm();
  // };

const generateCSV = (data) => {
  const headers = [
    ['eYantra Industries Private Limited'],
    ['COST SHEET'],
    ['Date', data.date, data.time],
    ['Created By', data.createdBy],
    ['Location', data.location],
    ['Email', data.userEmail]
    ['Cost Sheet for', data.costsheetfor],
    ['Name of the Client', data.clientName],
    ['Payment Terms', data.clientPaymentTerms === 'custom' ? customClientPaymentTerm : data.clientPaymentTerms],
    ['Sales Order No', data.salesOrderNo],
    ['Sales POC', data.salesPOC],
    ['SCM SPOC', data.scmspoc],
    
    ['Details', 'Qty', 'GST', 'PP', 'NLC', 'SP', 'NSP', 'Total PP', 'Total SP', 'Margin', 'Margin %', 'Vendor', 'Vendor Payment Terms'],
  ];

  const rows = data.items.map(item => [
    item.details,
    item.qty,
    item.gst,
    item.pp,
    item.nlc,
    item.sp,
    item.nsp,
    item.totalPp,
    item.totalSp,
    item.margin,
    item.marginPercent,
    item.vendorName,
    item.vendorPaymentTerms === 'custom' ? customVendorPaymentTerm : item.vendorPaymentTerms,
  ]);

//  useEffect(() => {
//    const transportationTotalPp = parseFloat(formData.transportationTotalPp) || 0;
//    const customizationTotalPp = parseFloat(formData.customizationTotalPp) || 0;
//    const marketingTotalPp = parseFloat(formData.marketingTotalPp) || 0;
//
//    const totalPP = formData.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
//    const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
//    const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
//    const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;
//
//    setFormData(prevState => ({
//      ...prevState,
//      totalPP: totalPP.toFixed(2),
//      totalSP: totalSP.toFixed(2),
//      totalMargin: totalMargin.toFixed(2),
//      totalMarginPercent: totalMarginPercent.toFixed(2),
//    }));
//  }, [formData.items, formData.transportationTotalPp, formData.customizationTotalPp, formData.marketingTotalPp]);


  const transportationTotalPp = parseFloat(data.transportationTotalPp) || 0;
  const customizationTotalPp = parseFloat(data.customizationTotalPp) || 0;
  const marketingTotalPp = parseFloat(data.marketingTotalPp) || 0;

  const transportationTotalSp = parseFloat(data.transportationTotalSp) || 0;
  const customizationTotalSp = parseFloat(data.customizationTotalSp) || 0;
  const marketingTotalSp = parseFloat(data.marketingTotalSp) || 0;

  // const totalPP = data.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
  // const totalSP = data.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
  // const totalMargin = data.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
  const totalPP = data.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp ;
  const totalSP = data.items.reduce((total, item) => total + parseFloat(item.totalSp), 0) + transportationTotalSp + customizationTotalSp + marketingTotalSp;
  const totalMargin = data.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp - transportationTotalSp - customizationTotalSp - marketingTotalSp;
  const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;

  // const additionalRows = [
  //   ['Transportation', '', '', '', '', '', '', transportationTotalPp.toFixed(2), '', (-transportationTotalPp).toFixed(2), '-100%', '', ''],
  //   ['Customization', '', '', '', '', '', '', customizationTotalPp.toFixed(2), '', (-customizationTotalPp).toFixed(2), '-100%', '', ''],
  //   ['Marketing Promotions', '', '', '', '', '', '', marketingTotalPp.toFixed(2), '',(-marketingTotalPp).toFixed(2), '-100%', '', ''],
  // ];

  const additionalRows = [
    // ['Transportation', '', '', '', '', '', '', transportationTotalPp.toFixed(2), transportationTotalSp.toFixed(2), (-transportationTotalPp).toFixed(2), '-100%', '', ''],
    ['Transportation', '', '', '', '', '', '', transportationTotalPp.toFixed(2), transportationTotalSp.toFixed(2), ((transportationTotalSp)-(transportationTotalPp)).toFixed(2), ((totalMarginTranspotation/formData.transportationTotalPp)*100).toFixed(2), '', ''],
    ['Customization', '', '', '', '', '', '', customizationTotalPp.toFixed(2), customizationTotalSp.toFixed(2), ((customizationTotalSp)-(customizationTotalPp)).toFixed(2), ((totalMarginCustomization/formData.customizationTotalPp)*100).toFixed(2), '', ''],
    ['Marketing Promotions', '', '', '', '', '', '', marketingTotalPp.toFixed(2), marketingTotalSp.toFixed(2), ((marketingTotalSp)-(marketingTotalPp)).toFixed(2), ((totalMarginMarketing/formData.marketingTotalPp)*100).toFixed(2), '', ''],
  ];

  const totalMarginPrice=  (totalSP)-(totalPP).toFixed(1);
  const totalMarginPercentage = (totalMarginPrice/totalPP*100).toFixed(1);
  const totalRow = [
    'Total',
    '',
    '',
    '',
    '',
    '',
    '',
    totalPP.toFixed(2),
    totalSP.toFixed(2),
    // totalMargin.toFixed(2),
    totalMarginPrice,
    // totalMarginPercent.toFixed(2),
    totalMarginPercentage,
    '',
    ''
  ];

  const csvContent = [...headers, ...rows, ...additionalRows, totalRow]
    .map(e => e.join(","))
    .join("\n");

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  downloadLinkRef.current.href = url;
  downloadLinkRef.current.download = `cost_sheet_${formData.clientName}_${formData.salesOrderNo}_${formData.date}.csv`;
  downloadLinkRef.current.click();
};


  const updateMasterCSV = async (data) => {
    const response = await fetch(`${urlconfig.frontend_server}/api/update-master-csv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      console.log('Master CSV updated successfully');
    } else {
      console.error('Failed to update master CSV');
    }
  };

  const clearForm = () => {
    setFormData({
      date: '',
      clientName: '',
      clientPaymentTerms: '',
      salesOrderNo: '',
      salesPOC: '',
      items: [initialItemState],
    });
    setCollapsedItems([]);
    setShowCustomClientPaymentTerm(false);
    setShowCustomVendorPaymentTerm(false);
    setCustomClientPaymentTerm('');
    setCustomVendorPaymentTerm('');
  };

  const totalMarginMarketing = `${-(formData.marketingTotalPp-formData.marketingTotalSp)}`;
  const totalMarginTranspotation = `${-(formData.transportationTotalPp - formData.transportationTotalSp)}`;
  const totalMarginCustomization = `${-(formData.customizationTotalPp - formData.customizationTotalSp)}`;
  

  return (
    <form onSubmit={handleSubmit} className="csv-form">
  <div className="container-fluid mt-4">
    <div className="text-center mb-4">
      {/* <h2>eYantra Industries Private Limited</h2> */}
      <h1 className='mt-3 fw-bold fs-2'>COST SHEET</h1>
    </div>

    <Row className='mb-3'>

      <Col md={4}>
        <label htmlFor="costsheetfor" className='fw-bold'>Cost Sheet for:</label>
          <select
            className="form-control"
            name="costsheetfor"
            value={formData.costsheetfor}
            onChange={(e) => handleFormDataChange('costsheetfor', e.target.value)}
            required
          >
            <option value="" hidden>Create Cost Sheet</option>
            <option value="eyantraventures">Eyantra Ventures</option>
            <option value="Mozato">Mozato</option>
            <option value="eYantraIndustries">eYantra Industries</option>
            
          </select>
          </Col>
          <Col md={4}>
            
            <label htmlfor="createdBy" className='fw-bold'>Created By </label>
            <input type='disabled' className='form-control' name='createdBy' value={username} disabled></input>
          </Col>
          <Col md={4}>
            <label htmlfor="userEmail" className='fw-bold'>Email </label>
            <input type='disabled' className='form-control' name='userEmail' value={email} disabled></input>
          </Col>
    </Row>


    <div className="row mb-3">
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            className="form-control"
            name="date"
            value={formData.date}
            onChange={(e) => handleFormDataChange('date', e.target.value)}
            required
          />
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="clientName">Name of the Client:</label>
          <input
            type="text"
            className="form-control"
            name="clientName"
            value={formData.clientName}
            onChange={(e) => handleFormDataChange('clientName', e.target.value)}
            required
          />
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="clientPaymentTerms">Client Payment Terms:</label>
          <select
            className="form-control"
            name="clientPaymentTerms"
            value={formData.clientPaymentTerms}
            onChange={(e) => handleFormDataChange('clientPaymentTerms', e.target.value)}
            required
          >
            <option value="" hidden>Select Payment Term</option>
            <option value="advance">Advance</option>
            <option value="against-delivery">Against Delivery</option>
            <option value="10-days">10 days</option>
            <option value="15-days">15 days</option>
            <option value="30-days">30 days</option>
            <option value="45-days">45 days</option>
            <option value="60-days">60 days</option>
            <option value="custom">Custom</option>
          </select>
          {showCustomClientPaymentTerm && (
            <input
              type="text"
              className="form-control mt-2"
              name="customClientPaymentTerm"
              value={customClientPaymentTerm}
              onChange={(e) => setCustomClientPaymentTerm(e.target.value)}
              placeholder="Enter custom payment term"
              required
            />
          )}
        </div>
      </div>
    </div>

    <div className="row mb-3">
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="salesOrderNo">Sales Order No:</label>
          <input
            type="text"
            className="form-control"
            name="salesOrderNo"
            value={formData.salesOrderNo}
            onChange={(e) => handleFormDataChange('salesOrderNo', e.target.value)}
            onBlur={() => handleBlur('salesOrderNo')}
            // disabled={salesOrderExists}
            required
          />
          {salesOrderErrorMessage && (
            <div className="text-danger mt-2">{salesOrderErrorMessage}</div>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="salesPOC">Sales POC:</label>
          <input
            type="text"
            className="form-control"
            name="salesPOC"
            value={formData.salesPOC }
            onChange={(e) => handleFormDataChange('salesPOC', e.target.value)}
            required
          />
        </div>
        
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="scmspoc">SCM SPOC:</label>
          <input
            type="text"
            className="form-control"
            name="scmspoc"
            value={formData.scmspoc}
            onChange={(e) => handleFormDataChange('scmspoc', e.target.value)}
            required
          />
        </div>
        
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            className="form-control"
            name="location"
            value={formData.location}
            onChange={(e) => handleFormDataChange('location', e.target.value)}
            required
          />
        </div>
      </div>
    </div>

    {/* pending field */}

    {/* <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="pending">pending Status:</label>
          <input
            type="text"
            className="form-control"
            name="pending"
            value={formData.pending}
            onChange={(e) => handleFormDataChange('pending', e.target.value)}
            required
          />
        </div>
        
      </div> */}
    

    <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Details</th>
            <th>Qty</th>
            <th>GST</th>
            <th>PP</th>
            <th>NLC</th>
            <th>SP</th>
            <th>NSP</th>
            <th>Total PP</th>
            <th>Total SP</th>
            <th>Margin</th>
            <th>Margin %</th>
            <th>Vendor</th>
            <th>Vendor Payment Terms</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {formData.items.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={item.details}
                  onChange={(e) => handleItemChange(index, 'details', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={item.qty}
                  onChange={(e) => handleItemChange(index, 'qty', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={item.gst}
                  onChange={(e) => handleItemChange(index, 'gst', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={item.pp}
                  onChange={(e) => handleItemChange(index, 'pp', e.target.value)}
                  required
                />
              </td>
              <td>
                <span>{item.nlc}</span>
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={item.sp}
                  onChange={(e) => handleItemChange(index, 'sp', e.target.value)}
                  required
                />
              </td>
              <td>
                <span>{item.nsp}</span>
              </td>
              <td>
                <span>{item.totalPp}</span>
              </td>
              <td>
                <span>{item.totalSp}</span>
              </td>
              <td>
                <span>{item.margin}</span>
              </td>
              <td>
                <span>{item.marginPercent}</span>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={item.vendorName}
                  onChange={(e) => handleItemChange(index, 'vendorName', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name='vendorPaymentTerms'
                  value={item.vendorPaymentTerms}
                  onChange={(e) => handleItemChange(index, 'vendorPaymentTerms', e.target.value)}
                  required
                />
                {/* <select
                  className="form-control"
                  name="vendorPaymentTerms"
                  value={item.vendorPaymentTerms}
                  onChange={(e) => handleItemChange(index, 'vendorPaymentTerms', e.target.value)}
                  required
                >
                  <option value="">Select Payment Term</option>
                  <option value="advance">Advance</option>
                  <option value="against-delivery">Against Delivery</option>
                  <option value="10-days">10 days</option>
                  <option value="15-days">15 days</option>
                  <option value="30-days">30 days</option>
                  <option value="45-days">45 days</option>
                  <option value="60-days">60 days</option>
                  <option value="custom">Custom</option>
                </select>
                {showCustomVendorPaymentTerm && (
                  <input
                    type="text"
                    className="form-control mt-2"
                    name="customVendorPaymentTerm"
                    value={customVendorPaymentTerm}
                    onChange={(e) => setCustomVendorPaymentTerm(e.target.value)}
                    placeholder="Enter custom payment term"
                    required
                  />
                )} */}
              </td>
              <td>
                <button type="button" className="btn btn-danger" onClick={() => removeItem(index)}>
                  Remove
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>Transportation</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <input
                type="number"
                className="form-control"
                value={formData.transportationTotalPp}
                onChange={(e) => handleFormDataChange('transportationTotalPp', e.target.value)}
                required
              />
            </td>
            <td>
              <input
                type="number"
                className="form-control"
                value={formData.transportationTotalSp}
                onChange={(e) => handleFormDataChange('transportationTotalSp', e.target.value)}
                required
              />
            </td>
            <td>
              {/* <span>{-formData.transportationTotalPp}</span> */}
              {/* <span>{-(formData.transportationTotalPp-formData.transportationTotalSp)}</span> */}
              <span>{totalMarginTranspotation}</span>
            </td>
            {/* <td>-100%</td> */}
            <td>{(totalMarginTranspotation/formData.transportationTotalPp*100).toFixed(2)} </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Customization</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <input
                type="number"
                className="form-control"
                value={formData.customizationTotalPp}
                onChange={(e) => handleFormDataChange('customizationTotalPp', e.target.value)}
                required
              />
            </td>
            <td>
              <input
                type="number"
                className="form-control"
                value={formData.customizationTotalSp}
                onChange={(e) => handleFormDataChange('customizationTotalSp', e.target.value)}
                required
              />
            </td>
            <td>
              {/* <span>{-formData.customizationTotalPp}</span> */}
              {/* <span>{-(formData.customizationTotalPp - formData.customizationTotalSp)}</span> */}
              <span>{totalMarginCustomization}</span>
            </td>
            {/* <td>-100%</td> */}
            <td>{(totalMarginCustomization/formData.customizationTotalPp*100).toFixed(2)}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Marketing</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <input
                type="number"
                className="form-control"
                value={formData.marketingTotalPp}
                onChange={(e) => handleFormDataChange('marketingTotalPp', e.target.value)}
                required
              />
            </td>
            <td>
              <input
                type="number"
                className="form-control"
                value={formData.marketingTotalSp}
                onChange={(e) => handleFormDataChange('marketingTotalSp', e.target.value)}
                required
              />
            </td>
            <td>
              {/* <span>{-formData.marketingTotalPp}</span> */}
              {/* <span>{-(formData.marketingTotalPp-formData.marketingTotalSp)}</span> */}
              <span>{totalMarginMarketing}</span>
            </td>
            {/* <td>-100%</td> */}
            <td>{(totalMarginMarketing/formData.marketingTotalPp*100).toFixed(2)}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <span>₹{formData.totalPP}</span>
            </td>
            <td>
              <span>₹{formData.totalSP}</span>
            </td>
            <td>
              {/* <span>{formData.totalMargin}</span> */}
              ₹<span>{((formData.totalSP)-(formData.totalPP)).toFixed(1)}</span>
            </td>
            <td>
              {/* <span>{formData.totalMarginPercent}</span> */}
              <span>{(((formData.totalSP)-(formData.totalPP))/(formData.totalPP)*100).toFixed(1)}%</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <input type="disabled" className="form-control" value="pending"  disabled hidden /> 
    </div>

    <div className="text-center">
      <button type="button" className="btn btn-primary" onClick={addItem}>
        Add Item
      </button>
      <button type="submit" className="btn btn-success mx-2"
      disabled={salesOrderExists} >
        Submit Cost Sheet
      </button>
    
    </div>

    <Modal show={showModal} onHide={handleClose} className="custom-modal" >
        <Modal.Header>
          <Modal.Title>Confirm Your cost Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body >Please verify your details before submitting the cost sheet</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type='submit' onClick={handleConfirmCostSheet} disabled={loading}>

             {loading ? 'Submitting...' : 'Submit Cost Sheet'}
          </Button>
        </Modal.Footer>
      </Modal>



    <a ref={downloadLinkRef} style={{ display: 'none' }}>Download CSV</a>
  </div>

</form>




  );
};

export default UserInput;
