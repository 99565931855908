import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import UserInput from './UserInput';
import Dashboard from './Dashboard';
import Approvals from './Approvals';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import { useAuth, AuthProvider } from './AuthProvider';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import SalesDashboard from './dashboards/UserDashboard';
import ApprovalDashboard from './approvals/approval-dashboard';
import AdminDashboard from './dashboards/AdminDashboard';
import UserDashboard from './dashboards/UserDashboard';

const App = () => {
  const { account, signOut, email, username } = useAuth();
  // const emailIdsforAdmin = ['Mohammad.Ubaid@eyantra.com', 'Rahul.Rasa@eyantra.com', 'Vamsi.Perugu@eyantra.com', 'Sanjeev.Bandari@eyantra.com']
  const emailIdsforApprovals = ['Mohammad.Ubaid@eyantra.com', 'Rahul.Rasa@eyantra.com', 'Vamsi.Perugu@eyantra.com', 'Sanjeev.Bandari@eyantra.com']
  
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark fs-14">
            <div className="container align-items-center">
              {/* <Link to="/" className="navbar-brand fw-bolder">eYantra-Cost Sheet</Link> */}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link text-white">New Cost Sheet</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/approvals" className="nav-link text-white">Approvals</Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link to="/dashboard" className="nav-link text-white">Dashboard</Link>
                  </li> */}
                  <li className="nav-item">
                    <Link to="/user-dashboard" className="nav-link text-white">User Dashboard</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/cost-sheet-dashboard" className="nav-link text-white"> Admin Dashboard</Link>
                  </li> */}
                  {/* {emailIdsforAdmin.includes(email)&&(
                    <li className="nav-item">
                    <Link to="/cost-sheet-dashboard" className="nav-link text-white"> Admin Dashboard</Link>
                  </li>
                  )} */}
                   
                  <li className="nav-item">
                  <Link to="/cost-sheet-dashboard" className="nav-link text-white"> Dashboard</Link>
                  </li>
                
                  {emailIdsforApprovals.includes(email) &&(
                  <li className="nav-item">
                    <Link to="/approval-dashboard" className="nav-link text-white">Approvals</Link>
                  </li>
                  )}
                 
                </ul>
                {account && (
                  <div className="dropdown ms-3">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="accountDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {username}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="accountDropdown">
                      <li className="fs-6">
                        <a className="dropdown-item" href="#">User Email:
                          <br/> {email}</a>
                      </li>
                      <li className="nav-item">
                    <Link to="/user-dashboard" className="nav-link text-dark">User Dashboard</Link>
                  </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={signOut}>Sign Out</a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </nav>

          <div className="container-fluid mt-4">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<UserInput />} />
                <Route path="/approvals" element={<Approvals />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/user-dashboard" element={<UserDashboard/>} />
                {/* <Route path="/cost-sheet-dashboard" element={<AdminDashboard/>} /> */}
                {/* {emailIdsforAdmin.includes(email)&&(
                  <Route path="/cost-sheet-dashboard" element={<AdminDashboard/>} />
                )} */}
                <Route path="/cost-sheet-dashboard" element={<AdminDashboard/>} />
                
              
                {emailIdsforApprovals.includes(email) && (
                <Route path="/approval-dashboard" element={<ApprovalDashboard/>} />
                )}
              </Route>
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;

